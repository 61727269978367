import gql from "graphql-tag";

export const GetLeadsList = gql`
	query LeadsList(
		$filter: LeadFilter
		$after: Cursor
		$before: Cursor
		$limit: Int
	) {
		leads(
			filter: $filter
			first: $limit
			before: $before
			after: $after
			orderBy: CREATED_AT_DESC
		) {
			edges {
				node {
					id
					firstName
					lastName
					phoneNumber
					emailAddress
					city
					createdAt
					read
					postalCode
					provinceCode
					sourceUrl
					deleted
					meta
					state
					priority
					assignee
					company {
						name
						groupByPrimaryGroup {
							id
							cognitoName
						}
					}

					leadJourneys {
						nodes {
							stage
							product {
								id
								name
							}
						}
					}

					avaApp {
						id
						creditScore
						creditVerified
						creditRange
						creditBureaus(first: 1, orderBy: CREATED_AT_DESC) {
							nodes {
								id
								type
								expired
								createdAt
							}
						}
					}
					companyName
					jobTitle
					employmentStatus
					incomeAmount

					tradeApps(orderBy: CREATED_AT_ASC) {
						nodes {
							id
							tradeInValue
						}
					}
					leadCampaign {
						utmSource
						utmMedium
						utmContent
						utmCampaign
						s5
						s4
						s3
						s2
						s1
						id
					}
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const GetLead = gql`
	query Lead($id: UUID!) {
		lead(id: $id) {
			id
			emailAddress
			firstName
			lastName
			dateOfBirth
			streetNumber
			street
			city
			provinceCode
			postalCode
			phoneNumber
			emailAddress
			country
			sourceUrl
			createdAt
			read
			comment
			deleted
			meta
			state
			assignee
			priority

			company {
				id
				name
				distributorRelationshipsByDistributorCompanyId {
					nodes {
						id
						distributorCompany {
							id
							name
						}
						company {
							id
							name
						}
					}
				}
			}

			events {
				nodes {
					meta
					type
					updatedAt
					createdAt
				}
			}
			leadCampaign {
				id
				s1
				s2
				s3
				s4
				s5
				utmCampaign
				utmContent
				utmMedium
				utmSource
			}

			residenceTime
			rentOrOwn
			monthlyHomePayment
			employmentStatus
			incomeTime
			incomeAmount
			incomeSource
			jobTitle
			companyName
			salaryHourly
			desiredVehicle

			lenderQuotes(condition: { expired: false }) {
				nodes {
					id
					type
					provider
					quoteJson
					createdAt
					updatedAt
				}
			}

			additionalInformation

			avaApp {
				id
				creditScore
				creditVerified
				creditRange
				creditBureaus(first: 1, orderBy: CREATED_AT_DESC) {
					nodes {
						id
						type
						createdAt
						expired
						bureau {
							expired
							score {
								value
							}
							securedLoans {
								dateFiled
								SecuredLoanCreditorId
							}
							bankruptcies {
								dateFiled
								assignedDate
								assetAmount
								caseNumberAndTrustee
								description
								liabilityAmount
							}
							collections {
								assignedDate
								balanceAmount
								code
								dateOfLastPayment
								description
								originalAmount
								agency {
									id
									name
								}
							}
							inquiries {
								date
								inquirer {
									name
								}
							}
							trades {
								# Collapsed
								creditor {
									id
									name
								}
								# Collapsed & Expanded
								dateOpened # Date Opened
								balanceAmount # Balance
								paymentTermAmount # Payment Amount
								narratives
								# Overview
								## Balance
								## Date Opened
								association # Account Type
								portfolioType {
									code
									description
								} # this.code + paymentRate.code = Loan Status
								paymentRate {
									code
									description
								} # portfolioType.code + this.code = Loan Status
								highCreditAmount # High Credit Amount
								dateLastActivityOrPayment # Last Activity
								monthsReviewed # Months Reviewed
								# Payment Information
								## Payment Amount
								paymentTerm # Payment Term
								lastSlowPayment # Last Slow Payment
								# Slow Payment Counters
								derogatoryCounters {
									past30
									past60
									past90
								}
							}
							tradeLines {
								all {
									creditor {
										id
										name
									}
									date
									accountNumber
									association
									updateSource
									monthsReviewed
									dateOpened
									dateLastActivityOrPayment
									highCreditAmount
									paymentTerm
									paymentTermAmount
									narratives
									balanceAmount
									pastDueAmount
									lastSlowPayment
									portfolioType {
										code
										description
									}
									derogatoryCounters {
										past30
										past60
										past90
									}
									paymentRate {
										code
										description
									}
								}
								autoOver1500 {
									creditor {
										id
										name
									}
									date
									accountNumber
									association
									updateSource
									monthsReviewed
									dateOpened
									dateLastActivityOrPayment
									highCreditAmount
									paymentTerm
									paymentTermAmount
									narratives
									balanceAmount
									pastDueAmount
									lastSlowPayment
									portfolioType {
										code
										description
									}
									derogatoryCounters {
										past30
										past60
										past90
									}
									paymentRate {
										code
										description
									}
								}
								autoUnder1500 {
									creditor {
										id
										name
									}
									date
									accountNumber
									association
									updateSource
									monthsReviewed
									dateOpened
									dateLastActivityOrPayment
									highCreditAmount
									paymentTerm
									paymentTermAmount
									narratives
									balanceAmount
									pastDueAmount
									lastSlowPayment
									portfolioType {
										code
										description
									}
									derogatoryCounters {
										past30
										past60
										past90
									}
									paymentRate {
										code
										description
									}
								}
								nonAuto {
									creditor {
										id
										name
									}
									date
									accountNumber
									association
									updateSource
									monthsReviewed
									dateOpened
									dateLastActivityOrPayment
									highCreditAmount
									paymentTerm
									paymentTermAmount
									narratives
									balanceAmount
									pastDueAmount
									lastSlowPayment
									portfolioType {
										code
										description
									}
									derogatoryCounters {
										past30
										past60
										past90
									}
									paymentRate {
										code
										description
									}
								}
								overview {
									totalBalance
									currentAutoPayment
									autoTradeLinesBalance
									autoTradeLinesOver1500Balance
									autoTradeLinesOver1500Quantity
									autoTradeLinesQuantity
									autoTradeLinesUnder1500Balance
									autoTradeLinesUnder1500Quantity
									nonAutoTradeLinesBalance
									nonAutoTradeLinesQuantity
								}
							}
						}
					}
				}
			}

			tradeApps(orderBy: CREATED_AT_ASC) {
				nodes {
					id
					year
					make
					model
					trim
					mileage
					condition
					minTradeInValue
					maxTradeInValue
					tradeInValue
				}
			}
			lenderApps {
				nodes {
					id
					inovatecApplicationId
					inovatecApplicationNumber
					leadId
					monthlyHomePayment
					nodeId
					yearlyIncome
					employmentStatus
					createdAt
					updatedAt
					offers
					preferredOfferId
					vehicleListing {
						id
						listingMsrp
						listingPrice
						listingTitle
						listingUrl
						listingImageUrl
						nodeId
						vehicleCondition
						vehicleEngine
						vehicleExteriorColor
						vehicleInteriorColor
						vehicleMake
						vehicleMileage
						vehicleModel
						vehicleStatus
						vehicleStockNumber
						vehicleTransmission
						vehicleVin
						vehicleTrim
						vehicleYear
						createdAt
						updatedAt
					}
				}
			}
			appointments {
				nodes {
					appointmentAt
					vehicleListing {
						listingImageUrl
						listingTitle
						listingUrl
						vehicleStockNumber
					}
				}
			}
			retrieveIdVerification {
				action
				createdAt
				customerUid
				dashboardUrl
				duplicates {
					customerUid
					transactionId
					userId
				}
				entity
				fields {
					dateOfBirth {
						value
					}
					familyName {
						value
					}
					givenName {
						value
					}
					idIssuer {
						value
					}
					idType {
						value
					}
					middleName {
						value
					}
				}
				flags
				id
				images {
					front {
						croppedImage
						faceImage
						fullImage
					}
					liveness
				}
			}
			leadJourneys {
				nodes {
					createdAt
					updatedAt
					stage
					product {
						name
						id
					}
				}
			}
			incomeVerification {
				verifiedAt
				updatedAt
				id
				directDepositAccountId
				createdAt
				attributes {
					employerIncomeFrequency
					employerName
					loanElements {
						automotive
						microLoans
						mortgages
						otherLoans
						overallAmount
						studentLoans
					}
					riskElements {
						balanceMax
						balanceMin
						balanceTrend
						daysWithNegativeBalance90Days
					}
					verifiedIncome
				}
				accounts {
					accountNumber
					category
					currency
					id
					institutionNumber
					overdraftLimit
					transitNumber
					title
					type
					balance {
						available
						current
						limit
					}
				}
			}
		}
	}
`;

export const GetLeadCompany = gql`
	query LeadCompany($id: UUID!) {
		lead(id: $id) {
			id
			company {
				id
				rawMeta
				groupByPrimaryGroup {
					id
					cognitoName
				}
			}
		}
	}
`;

export const UpdateLeadField = gql`
	mutation UpdateLeadField($id: UUID!, $patch: LeadPatch!) {
		updateLead(input: { id: $id, patch: $patch }) {
			lead {
				id
				phoneNumber
				city
				emailAddress
			}
		}
	}
`;

export const UpdateReadStatus = gql`
	mutation UpdateReadStatus($id: UUID!) {
		updateLead(input: { id: $id, patch: { read: true } }) {
			lead {
				id
				read
			}
		}
	}
`;

export const UpdateLeadComment = gql`
	mutation UpdateLeadComment($id: UUID!, $comments: String!) {
		updateLead(input: { id: $id, patch: { comment: $comments } }) {
			lead {
				id
				comment
			}
		}
	}
`;

export const SoftDeleteLead = gql`
	mutation SoftDeleteLead($leadId: UUID!) {
		softDeleteLead(leadId: $leadId) {
			leadId
			success
		}
	}
`;

export const AdminSubmitLead = gql`
	mutation AdminSubmitLead(
		$companyId: Int!
		$messageOverride: String!
		$input: LeadInput!
		$productTypes: [ProductType]!
		$creditConsentLead: Boolean
	) {
		adminSubmitLead(
			companyId: $companyId
			messageOverride: $messageOverride
			input: $input
			productTypes: $productTypes
			creditConsentLead: $creditConsentLead
		) {
			leadId
			success
			deduplicated
		}
	}
`;

export const CreateTestLead = gql`
	mutation CreateTestLead($companyId: Int!, $sourceUrl: String!) {
		createTestLead(companyId: $companyId, sourceUrl: $sourceUrl) {
			leadId
			success
			deduplicated
		}
	}
`;

export const DistributeLead = gql`
	mutation DistributeLead($input: DistributeLeadInput!) {
		distributeLead(input: $input) {
			companyId
			leadId
			success
		}
	}
`;

export const GetLeadsByStage = gql`
	query GetLeadsByStage(
		$filter: LeadFilter
		$condition: LeadCondition
		$offset: Int
		$first: Int
		$orderBy: [LeadsOrderBy!]
	) {
		leads(
			filter: $filter
			condition: $condition
			first: $first
			offset: $offset
			orderBy: $orderBy
		) {
			nodes {
				id
				phoneNumber
				city
				firstName
				lastName
				dateOfBirth
				street
				streetNumber
				provinceCode
				country
				postalCode
				updatedAt
				idVerificationTemplateType
				retrieveIdVerification {
					action
					flags
					fields {
						idIssuer {
							value
						}
					}
					verificationUrl
				}
				leadJourneys {
					nodes {
						stage
						updatedAt
					}
				}
				avaApp {
					id
					creditScore
					creditVerified
					creditRange
					creditBureaus(first: 1, orderBy: CREATED_AT_DESC) {
						nodes {
							id
							type
							expired
							createdAt
						}
					}
				}
			}
			totalCount
		}
	}
`;

export const GetOverview = gql`
	query GetOverview($condition: LeadCondition) {
		creditScores: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT_SCORE] } } }
				leadJourneysExist: true
				avaApp: { creditScore: { notEqualTo: 0 } }
			}
		) {
			totalCount
			nodes {
				avaApp {
					creditScore
				}
			}
		}
		sources: leads(condition: $condition) {
			groupedAggregates(groupBy: SOURCE_URL) {
				distinctCount {
					id
				}
				keys
			}
		}
		tradeSources: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: TRADE } } }
				leadJourneysExist: true
			}
		) {
			groupedAggregates(groupBy: SOURCE_URL) {
				distinctCount {
					id
				}
				keys
			}
		}
		creditSources: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: CREDIT_SCORE } } }
				leadJourneysExist: true
			}
		) {
			groupedAggregates(groupBy: SOURCE_URL) {
				distinctCount {
					id
				}
				keys
			}
		}
		allLeads: leads(condition: $condition) {
			totalCount
		}
		openedLeads: leads(
			condition: $condition
			filter: { read: { equalTo: true } }
		) {
			totalCount
		}
		creditLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT_SCORE] } } }
				leadJourneysExist: true
			}
		) {
			totalCount
		}
		tradeLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [TRADE] } } }
				leadJourneysExist: true
			}
		) {
			totalCount
		}
		openedCreditLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: CREDIT_SCORE } } }
				leadJourneysExist: true
				read: { equalTo: true }
			}
		) {
			totalCount
		}
		openedTradeLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: TRADE } } }
				leadJourneysExist: true
				read: { equalTo: true }
			}
		) {
			totalCount
		}
		nonVerifiedCreditLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT] } } }
				leadJourneysExist: true
			}
		) {
			totalCount
		}
		driveBookings: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [DRIVE_COMPLETE] } } }
				leadJourneysExist: true
			}
		) {
			totalCount
		}
		idLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [ID_COMPLETE] } } }
				leadJourneysExist: true
			}
		) {
			totalCount
		}
	}
`;

export const GetMonthlyOverview = gql`
	query GetMonthlyOverview(
		$condition: LeadCondition
		$from: Datetime
		$to: Datetime
	) {
		allLeads: leads(
			condition: $condition
			filter: { createdAt: { greaterThan: $from, lessThan: $to } }
		) {
			totalCount
		}
		openedLeads: leads(
			condition: $condition
			filter: {
				read: { equalTo: true }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		creditLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT_SCORE] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		tradeLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [TRADE] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		nonVerifiedCreditLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		driveBookings: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [DRIVE_COMPLETE] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		idLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [ID_COMPLETE] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		fullAppLeads: leads(
			condition: $condition
			filter: {
				leadJourneys: { some: { stage: { in: [CREDIT_FULL] } } }
				leadJourneysExist: true
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		newStatusLead: leads(
			condition: $condition
			filter: {
				state: { in: [NEW] }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		workingStatusLead: leads(
			condition: $condition
			filter: {
				state: { in: [WORKING] }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		soldStatusLead: leads(
			condition: $condition
			filter: {
				state: { in: [SOLD] }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		contactedStatusLead: leads(
			condition: $condition
			filter: {
				state: { in: [CONTACTED] }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
		appointmentBookedStatusLead: leads(
			condition: $condition
			filter: {
				state: { in: [APPOINTMENT_BOOKED] }
				createdAt: { greaterThan: $from, lessThan: $to }
			}
		) {
			totalCount
		}
	}
`;
